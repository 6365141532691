import { Component } from '@angular/core';
import { CouponsGuide } from 'src/app/definitions/SectionsGuide.definition';
import { TitleType } from 'src/app/interfaces/Title.enum';

@Component({
  selector: 'app-coupons-page',
  templateUrl: './coupons-page.component.html',
  styleUrls: ['./coupons-page.component.scss'],
})
export class CouponsPageComponent {
  public readonly links = CouponsGuide;
  public readonly TitleType = TitleType;

  constructor() {}
}
