<div class="container pb-14">
  <div class="max-w-143.5 grid place-items-center bg-white shadow-container mx-auto rounded-2xl px-5 py-7">
    <app-title [type]="TitleType.MEDIUM" [text]="'user_profile_page.title.text'"></app-title>
    <app-description
      [type]="DescriptionType.MEDIUM"
      [text]="'user_profile_page.title.description'"
      customClasses="mb-6 mt-1"
    ></app-description>
    <app-form [formInfo]="formData" [filledInputs]="currentUser" (formDataEmitter)="emitFromForm($event)"></app-form>
    <p (click)="handleResetPassword()" class="font-medium cursor-pointer text-sky-700 text-medium-sm">
      {{ 'user_profile_page.reset_password.text' | translate }}
    </p>
  </div>
</div>
<app-bottom-image></app-bottom-image>
