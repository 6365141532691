import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminHeaderItems, UserHeaderItems } from 'src/app/definitions/Header.definitions';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { HeaderData, HeaderItem } from 'src/app/interfaces/Header.interface';
import { PopupType } from 'src/app/interfaces/Popup.enum';
import { Role } from 'src/app/interfaces/Role.enum';
import { AdminRoutes, UserRoutes } from 'src/app/interfaces/Routes.enum';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() headerData!: HeaderData;
  public headerItems: HeaderItem[];
  public ButtonType = ButtonType;
  public logoRouterLink: string = '';

  constructor(private auth: AuthenticationService, private router: Router, private popupDialog: PopupDialogService) {}

  ngOnInit(): void {
    switch (this.headerData.userRole) {
      case Role.ADMIN:
        this.headerItems = AdminHeaderItems;
        this.logoRouterLink = '/admin';
        break;
      case Role.USER:
        this.headerItems = UserHeaderItems;
        this.logoRouterLink = '/user';
        break;
      default:
        this.headerItems = [];
    }
  }

  public isActive(route: string): boolean {
    return (
      this.router.url === route ||
      (route != '/user' && this.router.url.includes(UserRoutes.EVENTS) && this.router.url.includes(route)) ||
      (route != '/user' &&
        this.router.url.includes(UserRoutes.PACKAGES) &&
        this.router.url.includes(route) &&
        !this.router.url.includes(AdminRoutes.ADMIN)) ||
      (route != '/admin' &&
        this.router.url.includes(route) &&
        this.router.url.includes(AdminRoutes.COUPONS) &&
        (this.router.url.includes(AdminRoutes.COUPONS_GENERATOR) || this.router.url.includes(AdminRoutes.USED_COUPONS)))
    );
  }

  public openSideContainer(): void {
    this.popupDialog.openSideContainerPopup({
      type: PopupType.SIDE_NAV_CONTAINER,
      headerItems: this.headerItems.map(headerItem => {
        return { ...headerItem, isActive: this.isActive(headerItem.href) };
      }),
    });
  }

  public logout(): void {
    this.auth.logout();
  }
}
