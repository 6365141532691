<app-table
  [data]="couponsData"
  [displayedColumns]="couponsColumns"
  [type]="TableType.USED_COUPON_DETAILS"
  pageTitle="admin_page.header_item.active_coupons"
  [loading]="loading"
  (selectionEmitter)="triggerSelection($event)"
></app-table>

<div class="w-full flex justify-end mt-5">
  <app-button
    (click)="downloadQrs()"
    [buttonInfo]="{
      text: 'coupons_page.button.download_qrs.text',
      type: ButtonType.ROYAL_BLUE_SMALL,
      isDisabled: !selectedItems.length
    }"
  ></app-button>
</div>
