import { NavigationPaths } from '../interfaces/NavigationPaths.enum';
import { Link } from '../interfaces/SectionsGuide.interface';

export const EventsGuide: Link[] = [
  {
    href: NavigationPaths.USER_EVENTS,
    text: 'user_page.event.sections_guide.events',
    isActive: true,
  },
  {
    href: NavigationPaths.USER_ACTIVE_EVENTS,
    text: 'user_page.event.sections_guide.active_events',
    isActive: false,
  },
  {
    href: NavigationPaths.USER_INACTIVE_EVENTS,
    text: 'user_page.event.sections_guide.inactive_events',
    isActive: false,
  },
];

export const OrdersGuide: Link[] = [
  {
    href: NavigationPaths.USER_EVENT_IMAGES,
    text: 'event_page.sections_guide.images',
    isActive: true,
  },
  {
    href: NavigationPaths.USER_EVENT_INFORMATION,
    text: 'event_page.sections_guide.information',
    isActive: false,
  },
  {
    href: NavigationPaths.USER_EVENT_ORDERS,
    text: 'event_page.sections_guide.active_orders',
    isActive: false,
  },
  {
    href: NavigationPaths.USER_EVENT_FINISHED_ORDERS,
    text: 'event_page.sections_guide.finished_orders',
    isActive: false,
  },
];

export const CouponsGuide: Link[] = [
  {
    href: NavigationPaths.ADMIN_COUPONS,
    text: 'coupons_page.sections_guide.active_coupons',
    isActive: true,
  },
  {
    href: NavigationPaths.ADMIN_USED_COUPONS,
    text: 'coupons_page.sections_guide.used_coupons',
    isActive: false,
  },
  {
    href: NavigationPaths.ADMIN_GENERATE_COUPONS,
    text: 'coupons_page.sections_guide.coupons_generator',
    isActive: false,
  },
];
