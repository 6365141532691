import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrdersDetailComponent } from './components/orders-detail/orders-detail.component';
import { OrdersComponent } from './components/orders/orders.component';
import { PackagesComponent } from './components/packages/packages.component';
import { localGuard } from './guard/local.guard';
import { privateGuard } from './guard/private.guard';
import { publicGuard } from './guard/public.guard';
import { Role } from './interfaces/Role.enum';
import { AdminRoutes, ClientRoutes, PublicUserRoutes, UserRoutes } from './interfaces/Routes.enum';
import { ActiveCouponsPageComponent } from './pages/active-coupons-page/active-coupons-page.component';
import { AdminProfilePageComponent } from './pages/admin-profile-page/admin-profile-page.component';
import { AdminStatisticsPageComponent } from './pages/admin-statistics-page/admin-statistics-page.component';
import { AdminUsersControlPageComponent } from './pages/admin-users-control-page/admin-users-control-page.component';
import { ClientEventPageComponent } from './pages/client-event-page/client-event-page.component';
import { ClientEventsPageComponent } from './pages/client-events-page/client-events-page.component';
import { ClientLoginPageComponent } from './pages/client-login-page/client-login-page.component';
import { CouponAutoSelectComponent } from './pages/coupon-auto-select/coupon-auto-select.component';
import { CouponGeneratorComponent } from './pages/coupon-generator/coupon-generator.component';
import { CouponsPageComponent } from './pages/coupons-page/coupons-page.component';
import { EventDetailComponent } from './pages/event-detail/event-detail.component';
import { EventPageComponent } from './pages/event-page/event-page.component';
import { ClientComponent } from './pages/layout/client/client.component';
import { DefaultComponent } from './pages/layout/default/default.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { PackagesPageComponent } from './pages/packages-page/packages-page.component';
import { PasswordResetPageComponent } from './pages/password-reset-page/password-reset-page.component';
import { PaymentFailedPageComponent } from './pages/payment-failed-page/payment-failed-page.component';
import { PaymentSuccessPageComponent } from './pages/payment-success-page/payment-success-page.component';
import { RegistrationPageComponent } from './pages/registration-page/registration-page.component';
import { ShoppingCartPageComponent } from './pages/shopping-cart-page/shopping-cart-page.component';
import { UsedCouponsPageComponent } from './pages/used-coupons-page/used-coupons-page.component';
import { UserActiveEventsPageComponent } from './pages/user-active-events-page/user-active-events-page.component';
import { UserAutoPackageSelectComponent } from './pages/user-auto-package-select/user-auto-package-select.component';
import { UserCreatedEventsPageComponent } from './pages/user-created-events-page/user-created-events-page.component';
import { UserEventInfoPageComponent } from './pages/user-event-info-page/user-event-info-page.component';
import { UserEventsPageComponent } from './pages/user-events-page/user-events-page.component';
import { UserFinishedOrdersPageComponent } from './pages/user-finished-orders-page/user-finished-orders-page.component';
import { UserInactiveEventsPageComponent } from './pages/user-inactive-events-page/user-inactive-events-page.component';
import { UserOrderDetailsPageComponent } from './pages/user-order-details-page/user-order-details-page.component';
import { UserOrdersPageComponent } from './pages/user-orders-page/user-orders-page.component';
import { UserProfilePageComponent } from './pages/user-profile-page/user-profile-page.component';
import { UserPurchasesPageComponent } from './pages/user-purchases-page/user-purchases-page.component';
import { UserStatisticsPageComponent } from './pages/user-statistics-page/user-statistics-page.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: PublicUserRoutes.LOGIN, pathMatch: 'full' },
      { path: PublicUserRoutes.LOGIN, component: LoginPageComponent, canActivate: [publicGuard] },
      { path: PublicUserRoutes.REGISTRATION, component: RegistrationPageComponent, canActivate: [publicGuard] },
      { path: PublicUserRoutes.PASSWORD_RESET, component: PasswordResetPageComponent, canActivate: [publicGuard] },
    ],
  },
  {
    path: PublicUserRoutes.PACKAGE_SELECTION,
    component: UserAutoPackageSelectComponent,
  },
  {
    path: PublicUserRoutes.COUPON_SELECTION,
    component: CouponAutoSelectComponent,
  },
  {
    path: UserRoutes.USER,
    component: DefaultComponent,
    canActivate: [privateGuard],
    data: { roleAccess: Role.USER },
    children: [
      {
        path: '',
        component: UserStatisticsPageComponent,
        canActivate: [privateGuard],
        data: { roleAccess: Role.USER },
      },
      {
        path: UserRoutes.EVENTS,
        component: UserEventsPageComponent,
        canActivate: [privateGuard],
        data: { roleAccess: Role.USER },
        children: [
          {
            path: '',
            component: UserCreatedEventsPageComponent,
            canActivate: [privateGuard],
            data: { roleAccess: Role.USER },
          },
          {
            path: UserRoutes.EVENTS_ACTIVE,
            component: UserActiveEventsPageComponent,
            canActivate: [privateGuard],
            data: { roleAccess: Role.USER },
          },
          {
            path: UserRoutes.EVENTS_INACTIVE,
            component: UserInactiveEventsPageComponent,
            canActivate: [privateGuard],
            data: { roleAccess: Role.USER },
          },
        ],
      },
      {
        path: UserRoutes.EVENT_DETAIL,
        component: EventPageComponent,
        canActivate: [privateGuard],
        data: { roleAccess: Role.USER },
        children: [
          {
            path: '',
            component: EventDetailComponent,
            canActivate: [privateGuard],
            data: { roleAccess: Role.USER },
          },
          {
            path: UserRoutes.ORDERS,
            component: UserOrdersPageComponent,
            canActivate: [privateGuard],
            data: { roleAccess: Role.USER },
          },
          {
            path: UserRoutes.EVENT_INFORMATION,
            component: UserEventInfoPageComponent,
            canActivate: [privateGuard],
            data: { roleAccess: Role.USER },
          },
          {
            path: UserRoutes.FINISHED_ORDERS,
            component: UserFinishedOrdersPageComponent,
            canActivate: [privateGuard],
            data: { roleAccess: Role.USER },
          },
          {
            path: UserRoutes.ORDER_DETAILS,
            component: UserOrderDetailsPageComponent,
            canActivate: [privateGuard],
            data: { roleAccess: Role.USER },
          },
          {
            path: UserRoutes.ORDER_FINISHED_DETAILS,
            component: UserOrderDetailsPageComponent,
            canActivate: [privateGuard],
            data: { roleAccess: Role.USER },
          },
        ],
      },
      {
        path: UserRoutes.PACKAGES,
        component: PackagesPageComponent,
        canActivate: [privateGuard],
        data: { roleAccess: Role.USER },
      },
      {
        path: UserRoutes.PACKAGES_SELECT,
        component: PackagesPageComponent,
        canActivate: [privateGuard],
        data: { roleAccess: Role.USER },
      },
      {
        path: UserRoutes.PURCHASES,
        component: UserPurchasesPageComponent,
        canActivate: [privateGuard],
        data: { roleAccess: Role.USER },
      },
      {
        path: UserRoutes.PAYMENT,
        children: [
          {
            path: UserRoutes.SUCCESS,
            component: PaymentSuccessPageComponent,
            canActivate: [privateGuard],
            data: { roleAccess: Role.USER },
          },
          {
            path: UserRoutes.CANCELED,
            component: PaymentFailedPageComponent,
            canActivate: [privateGuard],
            data: { roleAccess: Role.USER },
          },
        ],
      },
      {
        path: UserRoutes.PROFILE,
        component: UserProfilePageComponent,
        canActivate: [privateGuard],
        data: { roleAccess: Role.USER },
      },
    ],
  },
  {
    path: AdminRoutes.ADMIN,
    component: DefaultComponent,
    canActivate: [privateGuard],
    data: { roleAccess: Role.ADMIN },
    children: [
      {
        path: AdminRoutes.DASHBOARD,
        component: AdminStatisticsPageComponent,
        pathMatch: 'full',
        canActivate: [privateGuard],
        data: { roleAccess: Role.ADMIN },
      },
      {
        path: AdminRoutes.USERS,
        component: AdminUsersControlPageComponent,
        canActivate: [privateGuard],
        data: { roleAccess: Role.ADMIN },
      },
      {
        path: AdminRoutes.PACKAGES,
        component: PackagesComponent,
        canActivate: [privateGuard],
        data: { roleAccess: Role.ADMIN },
      },
      {
        path: AdminRoutes.ORDERS,
        component: OrdersComponent,
        canActivate: [privateGuard],
        data: { roleAccess: Role.ADMIN },
      },
      {
        path: AdminRoutes.ORDER_DETAIL,
        component: OrdersDetailComponent,
        canActivate: [privateGuard],
        data: { roleAccess: Role.ADMIN },
      },
      {
        path: UserRoutes.PROFILE,
        component: AdminProfilePageComponent,
        canActivate: [privateGuard],
        data: { roleAccess: Role.ADMIN },
      },
      {
        path: AdminRoutes.COUPONS,
        component: CouponsPageComponent,
        canActivate: [privateGuard],
        data: { roleAccess: Role.ADMIN },
        children: [
          {
            path: '',
            component: ActiveCouponsPageComponent,
            canActivate: [privateGuard],
            data: { roleAccess: Role.ADMIN },
          },
          {
            path: AdminRoutes.USED_COUPONS,
            component: UsedCouponsPageComponent,
            canActivate: [privateGuard],
            data: { roleAccess: Role.ADMIN },
          },
          {
            path: AdminRoutes.COUPONS_GENERATOR,
            component: CouponGeneratorComponent,
            canActivate: [privateGuard],
            data: { roleAccess: Role.ADMIN },
          },
        ],
      },
      {
        path: AdminRoutes.COUPONS_GENERATOR,
        component: CouponGeneratorComponent,
        canActivate: [privateGuard, localGuard],
        data: { roleAccess: Role.ADMIN },
      },
    ],
  },
  {
    path: ClientRoutes.CLIENT,
    component: ClientComponent,
    children: [
      { path: '', redirectTo: ClientRoutes.LOGIN, pathMatch: 'full' },
      { path: ClientRoutes.LOGIN, component: ClientLoginPageComponent, canActivate: [publicGuard] },
      {
        path: ClientRoutes.EVENTS,
        component: ClientEventsPageComponent,
        canActivate: [privateGuard],
        data: { roleAccess: Role.CLIENT },
      },
      {
        path: ClientRoutes.EVENT_DETAIL,
        component: ClientEventPageComponent,
        canActivate: [privateGuard],
        data: { roleAccess: Role.CLIENT },
      },
      {
        path: ClientRoutes.CART,
        component: ShoppingCartPageComponent,
        canActivate: [privateGuard],
        data: { roleAccess: Role.CLIENT },
      },
    ],
  },
  {
    path: '**',
    component: DefaultComponent,
    children: [{ path: '**', component: NotFoundPageComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
