<div *ngIf="data" class="mt-10">
  <div class="flex flex-wrap items-center justify-between w-full gap-3">
    <app-title *ngIf="!!pageTitle" [type]="TitleType.SMALL" [text]="pageTitle"></app-title>

    <app-date-range-search
      *ngIf="canBeFilteredByDate"
      (dateRangeEmitter)="searchByDate($event)"
    ></app-date-range-search>

    <div class="relative max-w-[260px] w-full">
      <app-icon
        class="absolute top-2/4 left-3.5 -translate-y-1/2"
        icon="search-icon.svg"
        color="gray-400"
        width="w-5"
      ></app-icon>
      <input
        class="w-full border border-slate-300 rounded pl-10 py-2.5 font-normal text-sm placeholder:text-slate-300"
        type="text"
        [placeholder]="'admin_page.users_table.input.search' | translate"
        [(ngModel)]="nameSearch"
        (ngModelChange)="filter()"
      />
    </div>
  </div>

  <div class="my-6 overflow-x-auto">
    <div class="inline-block min-w-full align-middle">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        (matSortChange)="announceSortChange()"
        class="min-w-full table-component"
      >
        <ng-container *ngFor="let columnObj of displayedColumns; let i = index" [matColumnDef]="columnObj.matColumnDef">
          <th
            mat-header-cell
            *matHeaderCellDef
            [disabled]="!columnObj.canBeSorted"
            mat-sort-header
            [sortActionDescription]="sortAction(columnObj.sortType)"
          >
            <mat-checkbox
              *ngIf="columnObj.type === FieldType.SELECT && i === 0"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
            {{ columnObj.text | translate }}
          </th>
          <td mat-cell *matCellDef="let fieldData">
            <mat-checkbox
              *ngIf="columnObj.type === FieldType.SELECT && i === 0"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(fieldData) && emitSelectedItem() : null"
              [checked]="selection.isSelected(fieldData)"
            >
            </mat-checkbox>

            <p *ngIf="!columnObj.type || columnObj.type === FieldType.MONEY" [class]="getClasses(columnObj?.styleData)">
              {{ fieldData[columnObj.matColumnDef] }}
            </p>
            <p *ngIf="columnObj.type === FieldType.BOOLEAN">
              <span
                [class]="
                  fieldData[columnObj.matColumnDef]
                    ? 'bg-green-50 text-green-700 ring-green-600/20'
                    : 'bg-red-50 text-red-700 ring-red-600/20'
                "
                class="inline-flex items-center px-2 py-1 text-xs font-medium rounded-md ring-1 ring-inset"
              >
                {{
                  (fieldData[columnObj.matColumnDef]
                    ? columnObj.textBasedOnBoolean.true
                    : columnObj.textBasedOnBoolean.false
                  ) | translate
                }}
              </span>
            </p>
            <p *ngIf="columnObj.type === FieldType.ROLE">
              {{ getUserRole(fieldData.role) | translate }}
            </p>

            <p *ngIf="columnObj.type === FieldType.DATE && fieldData[columnObj.matColumnDef]">
              {{ fieldData[columnObj.matColumnDef] | date : columnObj.dateOption }}
            </p>

            <button
              *ngIf="columnObj.type === FieldType.BUTTON"
              (click)="fieldButtonClick(fieldData)"
              class="font-semibold text-sky-700 font-montserrat"
            >
              {{ columnObj.text | translate }}
            </button>

            <button
              *ngIf="columnObj.type === FieldType.PDF_BUTTON && fieldData['status']"
              (click)="emitData(fieldData['id'])"
              class="font-semibold text-sky-700 font-montserrat"
            >
              {{ columnObj.text | translate }}
            </button>

            <div *ngIf="columnObj.type === FieldType.ICON" class="w-2.5 mx-auto">
              <app-icon icon="envelope-right-icon.svg" width="w-2" color="sky-700"></app-icon>
            </div>
          </td>
        </ng-container>

        <tr class="table-header" mat-header-row *matHeaderRowDef="columns"></tr>
        <tr
          class="table-data"
          [ngClass]="isRowClickEnabled ? 'cursor-pointer table-row-hover' : ''"
          mat-row
          *matRowDef="let row; columns: columns"
          (click)="onRowClick(row['id'])"
        ></tr>
      </table>
    </div>
  </div>

  <div *ngIf="!data.length || !dataSource.filteredData.length" class="text-center mt-7 text-slate-300">
    <p *ngIf="!loading">{{ (data.length ? 'general.table.no_results.text' : 'general.table.no_data') | translate }}</p>
    <p *ngIf="loading">{{ 'global.loading.text' | translate }}</p>
  </div>

  <app-pagination [pageSizeOptions]="[10, 50, 100]" [length]="dataSource.data.length" pageSize="10"></app-pagination>
</div>
