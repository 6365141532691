import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineClasses } from 'libs/common/utils/ClassesUtils';
import { firstValueFrom } from 'rxjs';
import { EditUserForm } from 'src/app/definitions/Form.definitions';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { FieldType } from 'src/app/interfaces/Column.enum';
import { DisplayedColumns, StyleColumnData } from 'src/app/interfaces/Column.interface';
import { FilledInputsForUserEdit } from 'src/app/interfaces/Form.interface';
import { Order } from 'src/app/interfaces/OrderDetails.interface';
import { PaymentStatus } from 'src/app/interfaces/Payment.enum';
import { Payment, PaymentInfo } from 'src/app/interfaces/Payment.interface';
import { PopupType } from 'src/app/interfaces/Popup.enum';
import { Role } from 'src/app/interfaces/Role.enum';
import { TableType } from 'src/app/interfaces/Table.enum';
import { CouponsData, EventsData, PurchaseData } from 'src/app/interfaces/Table.interface';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { User } from 'src/app/interfaces/User.interface';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';
import { UserService } from 'src/app/services/user.service';
import { PaginationComponent } from '../pagination/pagination.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T extends User | PaymentInfo | Payment | EventsData | Order | PurchaseData | CouponsData>
  implements OnInit, OnChanges, AfterContentChecked
{
  public dataCopyRes: T[];
  @Input() data: T[];
  @Input() displayedColumns: DisplayedColumns[];
  @Input() type: TableType;
  @Input() currentUser!: User;
  @Input() pageTitle: string;
  @Input() isRowClickEnabled!: boolean;
  @Input() canBeFilteredByDate!: boolean;
  @Input() loading!: boolean;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(PaginationComponent, { static: false }) set pagination(paginator: PaginationComponent) {
    if (paginator) {
      this.dataSource.paginator = paginator;
      this.paginator = paginator;
      this.selection.clear();
      this.emitSelectedItem();
    }
  }
  @Output() emitId = new EventEmitter<string>();
  @Output() dataEmitter = new EventEmitter<string>();
  @Output() selectionEmitter = new EventEmitter<
    (User | PaymentInfo | Payment | EventsData | Order | PurchaseData | CouponsData)[]
  >();
  private paginator: PaginationComponent;
  public columns: string[];
  public dataSource = new MatTableDataSource<T>([]);
  public nameSearch: string;
  public dataCopy: T[];
  public dateSearch: DateRange<Date>;
  public selection = new SelectionModel<User | PaymentInfo | Payment | EventsData | Order | PurchaseData | CouponsData>(
    true,
    []
  );
  public readonly TableType = TableType;
  public readonly ButtonType = ButtonType;
  public readonly TitleType = TitleType;
  public readonly FieldType = FieldType;
  public readonly PopupType = PopupType;
  public readonly PaymentStatus = PaymentStatus;

  constructor(
    public userService: UserService,
    private popupDialog: PopupDialogService,
    private router: Router,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.columns = this.displayedColumns.map(columnsObj => columnsObj.matColumnDef);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']?.currentValue) {
      this.dataCopy = this.data;
      this.dataSource = new MatTableDataSource(changes['data'].currentValue.length ? changes['data'].currentValue : []);
      this.announceSortChange();
      this.filter();
      if (this.dateSearch && !!this.dateSearch.start && !!this.dateSearch.end) {
        this.searchByDate(this.dateSearch);
      }
    }
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  public filter(): void {
    const filterValue = !!this.nameSearch ? this.nameSearch.trim().toLowerCase() : '';

    // Filter only the currently shown data based on specified columns
    this.dataSource.filterPredicate = (data, filter) => {
      const filterableColumns = this.displayedColumns
        //.filter(column => column.canBeSorted) // adjust based on your logic
        .map(column => {
          if (column.type === FieldType.BOOLEAN) {
            // For BOOLEAN type, use textBasedOnBoolean values for searching
            const booleanText = data[column.matColumnDef as keyof typeof data]
              ? this.translateService.instant(column.textBasedOnBoolean?.true)
              : this.translateService.instant(column.textBasedOnBoolean?.false);
            return booleanText !== undefined && booleanText !== null ? booleanText : '';
          } else if (column.type === FieldType.DATE) {
            return this.datePipe.transform(data[column.matColumnDef as keyof typeof data] as number, column.dateOption);
          } else if (column.type === FieldType.ROLE) {
            const columnValue = this.translateService.instant(
              this.getUserRole(data[column.matColumnDef as keyof typeof data] as Role)
            );
            return columnValue !== undefined && columnValue !== null ? columnValue : '';
          } else {
            // For other types, use the regular column value for searching
            const columnValue = column.needsTranslation
              ? this.translateService.instant(data[column.matColumnDef as keyof typeof data] as string)
              : data[column.matColumnDef as keyof typeof data];

            return columnValue !== undefined && columnValue !== null ? columnValue : '';
          }
        })
        .join(' ')
        .toLowerCase();

      return filterableColumns.includes(filter);
    };

    this.dataSource.filter = filterValue;
  }

  public getUserRole(role: Role): string {
    switch (role) {
      case Role.ADMIN:
        return 'admin_page.table.item.role.admin';
      case Role.USER:
        return 'admin_page.table.item.role.user';
      case Role.CLIENT:
        return 'admin_page.table.item.role.client';
      default:
        return '';
    }
  }

  public announceSortChange(): void {
    this.dataSource.sortingDataAccessor = (data: any, property: string): string => {
      const column = this.getColumnData(property);

      if (column?.type == FieldType.BOOLEAN) {
        return data[property] ? column.textBasedOnBoolean.true : column.textBasedOnBoolean.false;
      }

      if (column?.type == FieldType.MONEY) {
        const [price, currency] = data[property].split(' ');
        return (currency + price).toLowerCase();
      }

      return typeof data[property] == 'string' ? data[property].toLowerCase() : data[property];
    };

    this.dataSource.sort = this.sort;
  }

  public getColumnData(matColumnDef: string): DisplayedColumns {
    return this.displayedColumns.find(column => column.matColumnDef === matColumnDef);
  }

  public fieldButtonClick(data: FilledInputsForUserEdit): void {
    switch (this.type) {
      case TableType.USER_EDIT:
        this.popupDialog.openPopup({
          type: PopupType.FORM,
          title: 'admin_page.users_table.edit_user.form_popup.title',
          form: EditUserForm,
          filledInputs: data,
          secondButtonText: 'admin_page.users_table.edit_user.form_popup.button.delete_user',
          description: 'admin_page.users_table.edit_user.form_popup.description',
          button: 'admin_page.users_table.edit_user.form_popup.button.update_user',
        });
        break;
      case TableType.ORDER_VIEW:
        this.router.navigate([`admin/orders/${data.uuid}`]);
        break;
      // TODO: Use later when create coupon details page
      // case TableType.USED_COUPON_DETAILS:
      //   if ('id' in data) this.router.navigate([`admin/coupon/${data.id}`]);
      //   break;
    }
  }

  public sortAction(sortType: string | undefined): string {
    if (sortType) {
      return `Sort by ${sortType}`;
    }

    return '';
  }

  public onRowClick(id: string): void {
    this.emitId.emit(id);
  }

  public searchByDate(date: DateRange<Date>): void {
    this.dateSearch = date;
    this.dataSource = new MatTableDataSource(this.dataCopy.length ? this.dataCopy : []);
    this.filter();
    const dateValues = this.displayedColumns.find(value => value.type === FieldType.DATE);
    if (date.start && date.end) {
      this.dataSource.data = this.dataSource.data.filter((item: T) => {
        if (dateValues) {
          if (
            dateValues.matColumnDef in item &&
            typeof item[dateValues.matColumnDef as keyof T] === 'number' &&
            item[dateValues.matColumnDef as keyof T] <= date.end &&
            item[dateValues.matColumnDef as keyof T] >= date.start
          ) {
            return true;
          } else {
            return false;
          }
        }

        return true;
      });
    }
  }

  public emitData(id: string) {
    this.dataEmitter.emit(id);
  }

  public getClasses(styleData?: StyleColumnData): string {
    if (!styleData) {
      return '';
    }

    let classes: string[] = [];

    if (styleData.capitalize) {
      classes.push('capitalize');
    }

    if (styleData.color) {
      classes.push(`text-${styleData.color}`);
      if (styleData.outline) {
        classes.push(
          `ring-${styleData.color}/20`,
          `bg-${styleData.color}-light`,
          'px-2',
          'py-1',
          'rounded-md',
          'ring-1',
          'ring-inset'
        );
      }
    }

    return combineClasses(...classes);
  }

  public isAllSelected(): boolean {
    const startIndex = this.paginator ? this.paginator.pageIndex * this.paginator.pageSize : 0;
    const endIndex = this.paginator ? startIndex + this.paginator.pageSize : 0;
    const pageData = this.dataSource.filteredData.slice(startIndex, endIndex);

    return pageData.length === this.selection.selected.length;
  }

  public async masterToggle(): Promise<void> {
    const visibleTableData = await firstValueFrom(this.dataSource.connect());

    this.isAllSelected() ? this.selection.clear() : visibleTableData.forEach(row => this.selection.select(row));
    this.emitSelectedItem();
  }

  public emitSelectedItem(): void {
    this.selectionEmitter.emit(this.selection.selected);
  }
}
