<div class="container mt-10">
  <app-title [type]="TitleType.SMALL" text="Generator"></app-title>

  <form class="flex flex-col mt-6" (ngSubmit)="generateQrCode()">
    <label for="type">{{ 'coupon_generator.form.label.type' | translate }}*</label>
    <select
      class="border box-border min-w-full w-auto border-neutral-300 p-2.5 text-sm shadow-input rounded font-normal text-zinc-900 placeholder:text-neutral-400"
      id="type"
      [(ngModel)]="couponData.discountType"
      name="type"
    >
      <option *ngFor="let type of discountType" [ngValue]="type">
        {{ getOptionText(type) | translate }}
      </option>
    </select>

    <label *ngIf="!isEligibleForFreeEvent(couponData.discountType)" class="mt-2" for="discount"
      >{{ 'coupon_generator.form.label.discount' | translate }}*</label
    >
    <input
      *ngIf="!isEligibleForFreeEvent(couponData.discountType)"
      type="number"
      class="border box-border min-w-full w-auto border-neutral-300 p-2.5 text-sm shadow-input rounded font-normal text-zinc-900 placeholder:text-neutral-400"
      id="discount"
      min="1"
      name="discount"
      [(ngModel)]="couponData.discount"
    />

    <label *ngIf="!isEligibleForFreeEvent(couponData.discountType)" class="mt-2" for="stripeId"
      >{{ 'coupon_generator.form.label.stripe_promotion_id' | translate }}*</label
    >
    <input
      *ngIf="!isEligibleForFreeEvent(couponData.discountType)"
      type="text"
      class="border box-border min-w-full w-auto border-neutral-300 p-2.5 text-sm shadow-input rounded font-normal text-zinc-900 placeholder:text-neutral-400"
      id="stripeId"
      name="stripeId"
      [(ngModel)]="couponData.stripePromotionId"
    />

    <label *ngIf="isEligibleForFreeEvent(couponData.discountType)" class="mt-2" for="free-events">{{
      'coupon_generator.form.label.free_events' | translate
    }}</label>
    <input
      *ngIf="isEligibleForFreeEvent(couponData.discountType)"
      type="number"
      class="border box-border min-w-full w-auto border-neutral-300 p-2.5 text-sm shadow-input rounded font-normal text-zinc-900 placeholder:text-neutral-400"
      id="free-events"
      name="free-events"
      min="1"
      [(ngModel)]="couponData.freeEvents"
    />

    <label class="mt-2">{{ 'coupon_generator.form.label.expires' | translate }}*</label>
    <app-date-picker (emitSelectedDate)="setDate($event)"></app-date-picker>

    <label class="mt-2" for="onlyNewCustomers">{{ 'coupon_generator.form.label.for_new_customer' | translate }}*</label>
    <select
      class="border box-border min-w-full w-auto border-neutral-300 p-2.5 text-sm shadow-input rounded font-normal text-zinc-900 placeholder:text-neutral-400"
      id="onlyNewCustomers"
      [(ngModel)]="couponData.isOnlyForNewCustomers"
      name="onlyNewCustomers"
    >
      <option [ngValue]="true">{{ 'coupon_generator.form.option.yes' | translate }}</option>
      <option [ngValue]="false">{{ 'coupon_generator.form.option.no' | translate }}</option>
    </select>

    <label class="mt-2" for="onlyUseOnce">{{ 'coupon_generator.form.label.use_once' | translate }}*</label>
    <select
      class="border box-border min-w-full w-auto border-neutral-300 p-2.5 text-sm shadow-input rounded font-normal text-zinc-900 placeholder:text-neutral-400"
      id="onlyUseOnce"
      name="onlyUseOnce"
      [(ngModel)]="couponData.onlyUseOnce"
    >
      <option [ngValue]="true">{{ 'coupon_generator.form.option.yes' | translate }}</option>
      <option [ngValue]="false">{{ 'coupon_generator.form.option.no' | translate }}</option>
    </select>

    <label *ngIf="!isEligibleForFreeEvent(couponData.discountType)" class="mt-2" for="coupon"
      >{{ 'coupon_generator.form.label.coupon' | translate }}*</label
    >
    <input
      *ngIf="!isEligibleForFreeEvent(couponData.discountType)"
      placeholder="COUPON"
      class="border box-border min-w-full w-auto border-neutral-300 p-2.5 text-sm shadow-input rounded font-normal text-zinc-900 placeholder:text-neutral-400 resize-none"
      [(ngModel)]="couponsInput"
      id="coupon"
      name="coupon"
      required="true"
    />

    <label *ngIf="isEligibleForFreeEvent(couponData.discountType)" class="mt-2" for="coupons"
      >{{ 'coupon_generator.form.label.coupons' | translate }}*</label
    >
    <textarea
      *ngIf="isEligibleForFreeEvent(couponData.discountType)"
      placeholder="COUPON1, COUPON2, COUPON3..."
      class="h-60 border box-border min-w-full w-auto border-neutral-300 p-2.5 text-sm shadow-input rounded font-normal text-zinc-900 placeholder:text-neutral-400 resize-none"
      [(ngModel)]="couponsInput"
      id="coupons"
      name="coupons"
      required="true"
    ></textarea>

    <app-button
      class="my-4"
      [buttonInfo]="{
        type: ButtonType.ROYAL_BLUE,
        text: 'coupon_generator.form.button.generate',
        buttonActionType: buttonActionType.SUBMIT
      }"
    ></app-button>
  </form>
</div>
