import { HeaderItem } from '../interfaces/Header.interface';

export const AdminHeaderItems: HeaderItem[] = [
  {
    text: 'admin_page.header_item.dashboard',
    href: '/admin',
  },
  {
    text: 'admin_page.header_item.users',
    href: '/admin/users',
  },
  {
    text: 'admin_page.header_item.packages',
    href: '/admin/packages',
  },
  {
    text: 'admin_page.header_item.orders',
    href: '/admin/orders',
  },
  {
    text: 'coupon_generator_page.header_item.coupons',
    href: '/admin/coupons',
  },
];

export const UserHeaderItems: HeaderItem[] = [
  {
    text: 'user_page.header_item.dashboard',
    href: '/user',
  },
  {
    text: 'user_page.header_item.events',
    href: '/user/events',
  },
  {
    text: 'user_page.header_item.packages',
    href: '/user/packages',
  },
  {
    text: 'user_page.header_item.purchases',
    href: '/user/purchases',
  },
];
