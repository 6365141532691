import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isEligibleForFreeEvent } from 'libs/common/utils/CouponUtils';
import { UsedCouponsColumns } from 'src/app/definitions/Columns.definition';
import { CouponsGuide } from 'src/app/definitions/SectionsGuide.definition';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { CouponType } from 'src/app/interfaces/Coupon.enum';
import { TableType } from 'src/app/interfaces/Table.enum';
import { CouponsData } from 'src/app/interfaces/Table.interface';
import { CouponService } from 'src/app/services/coupon.service';

@Component({
  selector: 'app-used-coupons-page',
  templateUrl: './used-coupons-page.component.html',
  styleUrls: ['./used-coupons-page.component.scss'],
})
export class UsedCouponsPageComponent {
  public readonly usedCouponsColumns = UsedCouponsColumns;
  public readonly TableType = TableType;
  public readonly ButtonType = ButtonType;
  public readonly links = CouponsGuide;
  public usedCouponsData: CouponsData[] = [];
  public isDataLoaded: boolean = false;
  private selectedItems: string[] = [];

  constructor(private coupon: CouponService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.coupon.getUsedCouponsData().subscribe(usedCoupons => {
      this.usedCouponsData = usedCoupons.map((coupon): CouponsData => {
        let discount = '';
        if (!isEligibleForFreeEvent(coupon.discountType)) discount += '-';
        discount += !isEligibleForFreeEvent(coupon.discountType) ? coupon.discount : coupon.freeEvents;
        discount += isEligibleForFreeEvent(coupon.discountType)
          ? ' ' + this.translate.instant('global.text.free_events')
          : coupon.discountType === CouponType.AMOUNT
          ? '$'
          : '%';
        return {
          id: coupon.id,
          name: coupon.name,
          discount: discount,
          expired: coupon.expires.toMillis(),
        };
      });
      this.isDataLoaded = true;
    });
  }

  public triggerSelection(selected: any[]): void {
    this.selectedItems = selected.map(selection => {
      return selection.name;
    });
  }
}
