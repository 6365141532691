import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isEligibleForFreeEvent } from 'libs/common/utils/CouponUtils';
import { NgxSpinnerService } from 'ngx-spinner';
import { CouponsColumns } from 'src/app/definitions/Columns.definition';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { CouponType } from 'src/app/interfaces/Coupon.enum';
import { ServerApi } from 'src/app/interfaces/ServerApi.enum';
import { TableType } from 'src/app/interfaces/Table.enum';
import { CouponsData } from 'src/app/interfaces/Table.interface';
import { CouponService } from 'src/app/services/coupon.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-active-coupons-page',
  templateUrl: './active-coupons-page.component.html',
  styleUrls: ['./active-coupons-page.component.scss'],
})
export class ActiveCouponsPageComponent implements OnInit, AfterContentChecked {
  public readonly couponsColumns = CouponsColumns;
  public readonly TableType = TableType;
  public readonly ButtonType = ButtonType;
  public couponsData: CouponsData[] = [];
  public selectedItems: string[] = [];
  public loading: boolean = true;

  constructor(
    private coupon: CouponService,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.coupon.getCoupons().subscribe(coupons => {
      this.couponsData = coupons.map((coupon): CouponsData => {
        let discount = '';
        if (!isEligibleForFreeEvent(coupon.discountType)) discount += '-';
        discount += !isEligibleForFreeEvent(coupon.discountType) ? coupon.discount : coupon.freeEvents;
        discount += isEligibleForFreeEvent(coupon.discountType)
          ? ' ' + this.translate.instant('global.text.free_events')
          : coupon.discountType === CouponType.AMOUNT
          ? '$'
          : '%';
        return {
          id: coupon.id,
          name: coupon.name || coupon.id,
          discount: discount,
          expired: coupon.expires.toMillis(),
        };
      });

      this.cdr.detectChanges();
      this.spinner.hide();

      this.loading = false;
    });
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  public triggerSelection(selected: any[]): void {
    this.selectedItems = selected.map(selection => {
      return selection.id;
    });
  }

  public downloadQrs(): void {
    this.spinner.show();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.http
      .post(`${environment.serverApi}${ServerApi.DOWNLOAD_BULK_QRS}`, this.selectedItems, {
        headers,
        responseType: 'arraybuffer',
      })
      .subscribe(res => {
        const blob = new Blob([res], {
          type: 'application/zip',
        });
        const url = window.URL.createObjectURL(blob);
        window.open(url);

        this.spinner.hide();
      });
  }
}
