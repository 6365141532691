import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor() {}

  public getCookie(cookieName: string): string | undefined {
    let b = document.cookie.split('; ');
    for (let e = b.length - 1; e >= 0; e--) {
      let c = b[e].split('=');
      if (cookieName === c[0]) return c[1];
    }
    return undefined;
  }

  public saveCookie(cookieName: string, cookieData: string, expires: string) {
    document.cookie = cookieName + '=' + cookieData + ';expires=' + expires + ';path=/';
  }

  public removeCookie(cookieName: string) {
    document.cookie = cookieName + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}
