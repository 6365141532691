import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import firebase from 'firebase/compat/app';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ProfileComplete } from './definitions/Form.definitions';
import { PopupType } from './interfaces/Popup.enum';
import { User } from './interfaces/User.interface';
import { AuthenticationService } from './services/authentication.service';
import { CookieService } from './services/cookie.service';
import { CouponService } from './services/coupon.service';
import { LanguageService } from './services/language.service';
import { PopupDialogService } from './services/popup-dialog.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public currentUser: User;
  private userSubscription?: Subscription;
  private isDialogOpenned: boolean = false;

  constructor(
    private languageService: LanguageService,
    private userService: UserService,
    private auth: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private router: Router,
    private popupDialog: PopupDialogService,
    private coupon: CouponService,
    private cookie: CookieService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.languageService.initLanguage();

    this.userSubscription = this.userService.currentUser$.subscribe(async user => {
      if (this.currentUser && user && this.currentUser.role !== user.role) {
        window.location.reload();
      }

      if (user && 'isBlocked' in user && user.isBlocked) {
        this.auth.logout();
        this.router.navigate(['/login']);
        window.location.reload();
      }

      if (user) {
        const couponCode = this.coupon.checkSavedCoupons();
        if (couponCode) {
          this.coupon.applyCoupon(couponCode);
        }
        this.currentUser = user;
        this.spinner.hide();
        this.cdr.detectChanges();
        if (!this.isDialogOpenned && 'isProfileCompleted' in user && !user.isProfileCompleted) {
          this.popupDialog.openPopupWithoutClosing({
            type: PopupType.FORM,
            title: 'profile_incompleted.popup.title.text',
            form: ProfileComplete,
            filledInputs: {
              uuid: user.uuid,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              phoneNumber: user.phoneNumber,
            },
            blockClosing: true,
            description: 'profile_incompleted.popup.description.text',
            button: 'admin_page.users_table.edit_user.form_popup.button.update_user',
          });
          this.isDialogOpenned = !this.isDialogOpenned;
        } else if ('vipCoupons' in user) {
          var today = new Date();
          const isAlertShown = this.cookie.getCookie('vipCouponAlertShown');
          const expirationMinus30Days = firebase.firestore.Timestamp.fromMillis(
            user.vipCoupons.expiration.toMillis() - 30 * 24 * 60 * 60 * 1000 // 30 days in milliseconds
          );
          if (user.vipCoupons.expiration.toMillis() < firebase.firestore.Timestamp.fromDate(new Date()).toMillis()) {
            const res = await this.coupon.removeUsersVipCouponEvents();
            if (res) {
              this.popupDialog.openMessageModalPopup({
                msg: 'admin_page.message_modal.message.vip_coupon_expired',
                success: false,
              });
            }
          } else if (
            !isAlertShown &&
            expirationMinus30Days.toMillis() < firebase.firestore.Timestamp.fromDate(today).toMillis()
          ) {
            this.popupDialog.openMessageModalPopup({
              msg: this.translateService.instant('admin_page.message_modal.message.vip_coupon_expiration_alert', {
                eventsLeft: user.vipCoupons.leftEvents,
                date: user.vipCoupons.expiration.toDate().toLocaleDateString(),
              }),
              success: false,
            });
            const d = new Date();
            d.setTime(d.getTime() + 3 * 24 * 60 * 60 * 1000);
            let expires = d.toUTCString();
            this.cookie.saveCookie('vipCouponAlertShown', 'true', expires);
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }
}
